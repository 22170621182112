import { create } from 'zustand'

type PopupProps = {
  background: string
  color: string | undefined
  message: string
} | null

export const useStore = create<{
  popup: PopupProps
  setPopup: (value: PopupProps) => void
  newVersionAvailable: boolean
  setNewVersionAvailable: (value: boolean) => void
}>((set) => ({
  popup: null,
  setPopup: () => set((state) => ({ popup: state.popup })),
  newVersionAvailable: false,
  setNewVersionAvailable: () => set((state) => ({ newVersionAvailable: state.newVersionAvailable })),
}))
