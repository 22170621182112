import '@fontsource/montserrat/latin-400.css'
import '@fontsource/montserrat/latin-700.css'
import { keepPreviousData, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React, { Fragment } from 'react'
import ReactDOM from 'react-dom/client'
import {
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router'
import { basename } from 'ui/src/common/utils'
import './index.css'
import { rootLoader, jobsLoader, jobsAction, reportLoader, teamsLoader } from './lib/loaders.ts'
import { persistQueryClient, removeOldestQuery } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import * as Sentry from '@sentry/react'
import { trpcClient, TRPCProvider } from './lib/trpc'
import { createTRPCQueryUtils } from '@trpc/react-query'

Sentry.init({
  dsn: window.SENTRY_DSN,
  release: `danchiano-frontend@${window.COMMIT_HASH}`,
  enabled: process.env.NODE_ENV === 'production',
  environment: window.APP_ENV,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    // Sentry.replayIntegration({
    //   maskAllText: true,
    //   blockAllMedia: true,
    //   // Avoid slow radar animation rendering
    //   mutationLimit: 150,
    // })
  ],
  tracesSampleRate: 0.02,
  replaysSessionSampleRate: 0.02,
  replaysOnErrorSampleRate: 1.0,
})

// Lazy load the Sentry replay integration
import('@sentry/react').then((lazyLoadedSentry) => {
  Sentry.addIntegration(
    lazyLoadedSentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
      // Avoid slow radar animation rendering
      mutationLimit: 150,
    }),
  )
})

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter)

// TODO: When we remove redux remove this export
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: 'always',
      retry: false,
      placeholderData: keepPreviousData,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      throw error
    },
  }),
})

export const clientUtils = createTRPCQueryUtils({ queryClient, client: trpcClient })

if (process.env.NODE_ENV === 'production') {
  persistQueryClient({
    queryClient,
    persister: createSyncStoragePersister({
      storage: window.localStorage,
      retry: removeOldestQuery,
    }),
    buster: 'v2',
  })
}

const router = sentryCreateBrowserRouter(
  [
    {
      path: '*',
      // TODO: Supress warning for now
      hydrateFallbackElement: <Fragment />,
      lazy: () => import('./legacy/Common/App.tsx'),
    },
    {
      path: 'new',
      loader: rootLoader,
      // TODO: Supress warning for now
      hydrateFallbackElement: <Fragment />,
      children: [
        {
          lazy: () => import('./root.tsx'),
          children: [
            {
              index: true,
              lazy: () => import('./routes/panel.tsx'),
            },
            {
              path: 'applicants',
              lazy: () => import('./routes/applicants.tsx'),
            },
            {
              path: 'dashboard',
              lazy: () => import('./routes/dashboard.tsx'),
            },
            {
              path: 'employees',
              lazy: () => import('./routes/employees.tsx'),
              children: [
                {
                  path: ':employeeId/report',
                  loader: reportLoader,
                  lazy: () => import('./routes/employees.report.tsx'),
                },
              ],
            },
            {
              path: 'jobs',
              loader: jobsLoader,
              action: jobsAction(queryClient), // Add / remove favorites
              lazy: () => import('./routes/jobs._index.tsx'),
              children: [
                {
                  path: ':jobId/report',
                  loader: reportLoader,
                  lazy: () => import('./routes/jobs.report.tsx'),
                },
              ],
            },
            {
              path: 'login',
              lazy: () => import('./routes/login.tsx'),
            },
            {
              path: 'market',
              loader: reportLoader,
              lazy: () => import('./routes/market.tsx'),
            },
            {
              path: 'offers',
              lazy: () => import('./routes/offers.tsx'),
            },
            {
              path: 'register',
              lazy: () => import('./routes/register._index.tsx'),
            },
            {
              path: 'report',
              lazy: () => import('./routes/report.tsx'),
            },
            {
              path: 'teams/:teamId?',
              loader: teamsLoader,
              lazy: () => import('./routes/teams._index.tsx'),
              children: [
                {
                  path: 'report',
                  loader: reportLoader,
                  lazy: () => import('./routes/teams.report.tsx'),
                },
                {
                  path: 'distribution',
                  lazy: () => import('./routes/teams.distribution.tsx'),
                },
                {
                  path: 'board',
                  lazy: () => import('./routes/teams.board.tsx'),
                },
                {
                  path: 'evaluation',
                  lazy: () => import('./routes/teams.distribution.tsx'),
                },
              ],
            },
            {
              path: 'orgs',
              lazy: () => import('./routes/orgs._index.tsx'),
              children: [
                {
                  path: ':orgId/groups?/:groupId?',
                  children: [
                    {
                      index: true,
                      loader: reportLoader,
                      lazy: () => import('./routes/orgs.report.tsx'),
                    },
                    {
                      path: 'report',
                      loader: reportLoader,
                      lazy: () => import('./routes/orgs.report.tsx'),
                    },
                    {
                      path: 'distribution',
                      lazy: () => import('./routes/orgs.distribution.tsx'),
                    },
                    {
                      path: 'board',
                      lazy: () => import('./routes/orgs.board.tsx'),
                    },
                  ],
                },
              ],
            },

            {
              path: 'test',
              children: [
                {
                  index: true,
                  lazy: () => import('./routes/test/competences.tsx'),
                },
                {
                  index: true,
                  path: 'competences',
                  lazy: () => import('./routes/test/competences.tsx'),
                },
                {
                  path: 'values',
                  lazy: () => import('./routes/test/values.tsx'),
                },
                {
                  path: 'personality',
                  lazy: () => import('./routes/test/personality.tsx'),
                },
              ],
            },
          ],
        },
        {
          lazy: () => import('./root.beta.tsx'),
          children: [
            {
              path: 'generate',
              lazy: () => import('./routes/beta.generate.tsx'),
            },
            {
              path: 'report',
              lazy: () => import('./routes/report.tsx'),
            },
            // orgs/users
            {
              lazy: () => import('./routes/orgs.users._layout.tsx'),
              children: [
                {
                  path: 'orgs/:orgId/users/groups',
                  lazy: () => import('./routes/orgs.users._index.tsx'),
                },
                {
                  path: 'orgs/:orgId/users/groups/:groupId/describe/:indicator?/:step?',
                  lazy: () => import('./routes/orgs.users.describe.tsx'),
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  { basename },
)

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <TRPCProvider trpcClient={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </TRPCProvider>
  </React.StrictMode>,
)
