import { queryOptions, skipToken, type QueryClient } from '@tanstack/react-query'
import { stringify } from 'qs'
import { redirect, type LoaderFunctionArgs } from 'react-router'
import type { ProfileType } from 'types'
import _request from 'ui/src/common/utils/request'
import type { Home } from '../../../server/src/common/common.service'
import type { Employees } from '../../../server/src/employees/employees.service'
import { getProfiles, getSearchParams, getTeamOptionsPrefetch, type SearchParams } from './utils'
// Add polyfill for Object.groupBy
import 'core-js/actual/object/group-by'
import { clientUtils, queryClient } from '@/main'
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server'
import type { AppRouter } from 'server/trpc'

// Queries

export const getUserOptions = [
  undefined,
  process.env.NODE_ENV !== 'development' && typeof window !== 'undefined'
    ? { initialData: window.USER as RouterOutput['auth']['getUser'] }
    : undefined,
] as const

// TODO: return this state from auth.getUser()
// .catch((error) => {
//   if (error.response?.message === 'User is not logged in') return null
//   if (error.response?.message === 'Applicant is not fully registered') return error.response
//   if (error.response?.message === 'Company is not fully registered') return error.response
//   throw error
// }

export type RouterInput = inferRouterInputs<AppRouter>
export type RouterOutput = inferRouterOutputs<AppRouter>

export const getReportOptions = (
  profiles: RouterInput['report']['getReport'],
): [
  RouterInput['report']['getReport'] | typeof skipToken,
  {
    enabled: boolean
    placeholderData: (
      previousReport: RouterOutput['report']['getReport'] | undefined,
    ) => RouterOutput['report']['getReport']
  },
] => [
  Boolean(profiles.first) || Boolean(profiles.second) ? profiles : skipToken,
  {
    enabled: Boolean(profiles.first) || Boolean(profiles.second),
    placeholderData: (previousReport: RouterOutput['report']['getReport'] | undefined) => {
      const user = clientUtils.auth.getUser.getData()

      // const previousReport =
      //   queryClient.getQueriesData<Awaited<Report>>({ queryKey: ['report', 'profiles'], type: 'active' })[0]?.[1] ??
      //   previousData

      // Show profile data from lists meanwhile we are loading the entire profile:
      // - applicant: search in my profile | candidates list (TODO) | report list candidates (TODO)
      // - jobs:      search in jobs list | report list jobs
      // - members:   search in members list | report list members
      // - teams:     search in teams list | report list teams

      let first
      if (profiles.first?.type !== previousReport?.first?.type || profiles.first?.id !== previousReport?.first?.id) {
        if (profiles.first?.type === 'applicant') {
          if (user && user.type === profiles.first.type && user.id === profiles.first.id) {
            first = user
          }
        } else if (profiles.first?.type === 'employee') {
          const employees = queryClient.getQueriesData<Awaited<Employees>>({
            queryKey: ['employees'], // getEmployeesQuery
          })[0]?.[1]

          first = employees?.list.find((m) => m.id === profiles.first?.id)

          if (!first) {
            const reportListEmployees = clientUtils.report.getList.getData({
              profileType: 'employee',
              testType: 'competences',
            })
            first = reportListEmployees?.find((m) => m.id === profiles.first?.id)
          }
        } else if (profiles.first?.type === 'job') {
          const jobs = clientUtils.applicant.getJobs.getData()

          first = jobs?.list.find((m) => m.id === profiles.first?.id)

          if (!first) {
            const reportListJobs = clientUtils.report.getList.getData({
              profileType: 'job',
              testType: profiles.type ?? 'competences',
            })
            first = reportListJobs?.find((m) => m.id === profiles.first?.id)
          }
        } else if (profiles.first?.type === 'member') {
          const team = clientUtils.team.getTeam.getData(
            getTeamOptionsPrefetch(profiles.first.teamId!.toString(), profiles),
          )
          first = team?.members.find((m) => m.id === profiles.first?.id)

          if (!first) {
            const reportListMembers = clientUtils.report.getList.getData({
              profileType: 'member',
              testType: profiles.type ?? 'competences',
            })
            first = reportListMembers?.find((m) => m.id === profiles.first?.id)
          }
        } else if (profiles.first?.type === 'team') {
          const teams = clientUtils.team.getTeams.getData()
          first = teams?.find((m) => m.id === profiles.first?.id)

          if (!first) {
            const reportListTeams = clientUtils.report.getList.getData({
              profileType: 'team',
              testType: profiles.type ?? 'competences',
            })
            first = reportListTeams?.find((m) => m.id === profiles.first?.id)
          }
        }
      }

      let second
      if (
        profiles.second?.type !== previousReport?.second?.type ||
        profiles.second?.id !== previousReport?.second?.id
      ) {
        if (profiles.second?.type === 'applicant') {
          const user = clientUtils.auth.getUser.getData()

          if (user && user.type === profiles.second.type && user.id === profiles.second.id) {
            second = user
          }
        } else if (profiles.second?.type === 'employee') {
          const employees = queryClient.getQueriesData<Awaited<Employees>>({
            queryKey: ['employees'], // getEmployeesQuery
          })[0]?.[1]

          second = employees?.list.find((m) => m.id === profiles.second?.id)

          if (!second) {
            const reportListEmployees = clientUtils.report.getList.getData({
              profileType: 'employee',
              testType: 'competences',
            })
            second = reportListEmployees?.find((m) => m.id === profiles.second?.id)
          }
        } else if (profiles.second?.type === 'job') {
          const jobs = queryClient.getQueriesData<Awaited<Home>>({
            queryKey: ['jobs'], // getJobsQuery
          })[0]?.[1]

          second = jobs?.list.find((m) => m.id === profiles.second?.id)

          if (!second) {
            const reportListJobs = clientUtils.report.getList.getData({
              profileType: 'job',
              testType: profiles.type ?? 'competences',
            })
            second = reportListJobs?.find((m) => m.id === profiles.second?.id)
          }
        } else if (profiles.second?.type === 'member') {
          const team =
            profiles.second.teamId != null
              ? clientUtils.team.getTeam.getData(getTeamOptionsPrefetch(profiles.second.teamId.toString(), profiles))
              : undefined
          second = team?.members.find((m) => m.id === profiles.second?.id)

          if (!second) {
            const reportListMembers = clientUtils.report.getList.getData({
              profileType: 'member',
              testType: profiles.type ?? 'competences',
            })
            second = reportListMembers?.find((m) => m.id === profiles.second?.id)
          }
        } else if (profiles.second?.type === 'team') {
          const teams = clientUtils.team.getTeams.getData()
          second = teams?.find((m) => m.id === profiles.second?.id)

          if (!second) {
            const reportListTeams = clientUtils.report.getList.getData({
              profileType: 'team',
              testType: profiles.type ?? 'competences',
            })
            second = reportListTeams?.find((m) => m.id === profiles.second?.id)
          }
        }
      }

      return previousReport
        ? {
            ...previousReport,
            first: previousReport.first || first ? { ...previousReport.first, ...first } : undefined,
            second: previousReport.second || second ? { ...previousReport.second, ...second } : undefined,
          }
        : undefined
    },
  },
]

export const getProfileQuery = (firstType: ProfileType, firstId: number, testType: string) =>
  queryOptions({
    queryKey: ['home', firstType, firstId, testType],
    queryFn: () =>
      _request(`/api/v2/home?${stringify({ first: { type: firstType, ids: [firstId] }, type: testType })}`)
        // Tanstack query doesn't like undefined values
        .then((result) => result.list[0] ?? null) as Home,
  })

export const getHomeQuery = (profileType: ProfileType, { type, p, q, o }: SearchParams) =>
  queryOptions({
    queryKey: ['home', profileType, type, p, q, o],
    queryFn: () => _request(`/api/v2/home?${stringify({ type, first: { type: profileType }, p, q, o })}`) as Home,
  })

// // TODO: trpc.team.getTeam: create this placeholderData if necessary
// placeholderData: (previousData) => {
//   return (
//     queryClient.getQueriesData<Awaited<Team>>({ queryKey: ['teams', teamId], type: 'active' })[0]?.[1] ??
//     previousData
//   )
// },

/** @deprecated */
export const getEmployeesQuery_deprecated = (p: string, q: string, o: string) =>
  queryOptions({
    queryKey: ['employees', p, q, o],
    queryFn: () =>
      _request(
        `/api/v2/employees?${stringify({
          p: p || undefined,
          q: q || undefined,
          o: o || undefined,
        })}`,
      ) as Employees,
  })

export const getEmployeesQuery = (queryClient: QueryClient, { p, q, o }: SearchParams) =>
  queryOptions({
    queryKey: ['employees', p, q, o],
    queryFn: () => _request(`/api/v2/employees?${stringify({ p, q, o })}`) as Employees,
    placeholderData: (previousData) => {
      return (
        queryClient.getQueriesData<Awaited<Employees>>({ queryKey: ['employees'], type: 'active' })[0]?.[1] ??
        previousData
      )
    },
  })

// Loaders

// Wait for user to load
export const rootLoader = async () => {
  await clientUtils.auth.getUser.prefetch()

  return null
}

export const jobsLoader = ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url)
  const searchParams = getSearchParams(url.search)

  clientUtils.auth.getUser.prefetch()
  clientUtils.applicant.getJobs.prefetch(searchParams)

  return null
}

export const reportLoader = ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url)

  clientUtils.auth.getUser.prefetch()

  const [profiles, options] = getReportOptions(getProfiles(url.pathname, url.search))
  if (profiles !== skipToken) {
    queryClient.prefetchQuery(clientUtils.report.getReport.queryOptions(profiles, options))
  }

  // Always refresh on market to get last profiles compared
  if (url.pathname.startsWith('/new/market')) clientUtils.report.getReport.invalidate()

  return null
}

export const teamsLoader = ({ request, params }: LoaderFunctionArgs) => {
  const url = new URL(request.url)
  const profiles = getProfiles(url.pathname, url.search)

  clientUtils.team.getTeams.prefetch()
  if (params.teamId != null) {
    clientUtils.team.getTeam.prefetch(getTeamOptionsPrefetch(params.teamId, profiles))
  }

  return null
}

// Actions, TODO: lazy import it

export const jobsAction =
  (queryClient: QueryClient) =>
  async ({ request }: { request: Request }) => {
    const formData = await request.formData()
    const intent = formData.get('intent')
    const favorite = formData.get('favorite') === 'true'

    if (intent === 'favorite') {
      await _request(`/api/v2/applicants/jobs/${formData.get('jobId') as string}/favorite`, {
        method: favorite ? 'POST' : 'DELETE',
      }).finally(() => queryClient.invalidateQueries({ queryKey: ['jobs'] }))

      // Favorites add to top of the list, so we need to reset p searchParam
      if (favorite) {
        const search = formData.get('search')
        const searchParams = new URLSearchParams(typeof search === 'string' ? search : '')
        if (searchParams.has('p')) {
          searchParams.delete('p')
          const searchString = searchParams.toString()
          return redirect(`${formData.get('pathname') as string}${searchString !== '' ? '?' + searchString : ''}`)
        }
      }
    }

    return null
  }
