import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { parse, stringify } from 'qs'
// import { z } from 'zod'
import * as v from 'valibot'
import { matchPath, useLocation } from 'react-router'
import { useMemo } from 'react'
import type { RouterInput } from './loaders'
import { skipToken } from '@tanstack/react-query'

export const newFeature = typeof window !== 'undefined' && window.APP_ENV !== 'production'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const defaultSearchParams = {
  // Common
  'type': 'competences',
  // Jobs
  'q': '',
  'p': 0,
  'o': 'added',
  // Report
  'page-type': 'detailed',
  'report-sort': 'index',
  // Distribution
  'dist-sort': 'desc',
  'dist-position': 'highs',
  // Board
  'board-type': 'competences',
  'board-sort': 'index',
  'board-axis': 'x',
  // Orgs
  'org-type': 'basic',
} as const

// const searchParamsSchema = z.object({
//   // Common
//   'type': z.enum(['competences', 'values', 'personality']).default(defaultSearchParams['type']),
//   'profiles': z.string().optional(),
//   'redirect': z.string().optional(),
//   // Jobs
//   'q': z.string().default(defaultSearchParams['q']),
//   'p': z.coerce.number().min(0).default(defaultSearchParams['p']),
//   'o': z.enum(['added', 'adjust', 'global']).default(defaultSearchParams['o']),
//   // Report
//   'page-type': z.enum(['detailed', 'resume']).default(defaultSearchParams['page-type']),
//   'report-sort': z.enum(['index', 'desc']).default(defaultSearchParams['report-sort']),
//   // Distribution
//   'dist-sort': z.enum(['desc', 'index']).default(defaultSearchParams['dist-sort']),
//   'dist-position': z.enum(['highs', /* 'medium', */ 'lows']).default(defaultSearchParams['dist-position']),
//   // Board
//   'board-type': z.enum(['competences', 'vectors', 'dimensions']).default(defaultSearchParams['board-type']),
//   'board-sort': z.enum(['index', 'desc', 'asc']).default(defaultSearchParams['board-sort']),
//   'board-id': z.coerce.number().optional(), // member, competence, vector, dimension
//   'board-axis': z.enum(['x', 'y']).default(defaultSearchParams['board-axis']),
//   'board-position': z.coerce.number().optional(),
// })

// type SearchParamsInput = z.input<typeof searchParamsSchema>
// export type SearchParams = z.infer<typeof searchParamsSchema>

const searchParamsSchema = v.object({
  // Common
  'type': v.optional(v.picklist(['competences', 'values', 'personality']), defaultSearchParams['type']),
  'profiles': v.optional(v.string()),
  'redirect': v.optional(v.string()),
  // Jobs
  'q': v.optional(v.string(), defaultSearchParams['q']),
  'p': v.optional(v.pipe(v.unknown(), v.transform(Number), v.minValue(0)), defaultSearchParams['p']),
  'o': v.optional(v.picklist(['added', 'adjust', 'global']), defaultSearchParams['o']),
  // Report
  'page-type': v.optional(v.picklist(['detailed', 'resume']), defaultSearchParams['page-type']),
  'report-sort': v.optional(v.picklist(['index', 'desc']), defaultSearchParams['report-sort']),
  // Distribution
  'dist-sort': v.optional(v.picklist(['desc', 'index']), defaultSearchParams['dist-sort']),
  'dist-position': v.optional(v.picklist(['highs', /* 'medium', */ 'lows']), defaultSearchParams['dist-position']),
  // Board
  'board-type': v.optional(v.picklist(['competences', 'vectors', 'dimensions']), defaultSearchParams['board-type']),
  'board-sort': v.optional(v.picklist(['index', 'desc', 'asc']), defaultSearchParams['board-sort']),
  'board-id': v.optional(v.string()), // member, org_user, competence, vector, dimension
  'board-axis': v.optional(v.picklist(['x', 'y']), defaultSearchParams['board-axis']),
  'board-position': v.optional(v.string()),
  // Orgs
  'org-type': v.optional(v.picklist(['basic', 'professional', 'transversal']), defaultSearchParams['org-type']),
})

type SearchParamsInput = v.InferInput<typeof searchParamsSchema>
export type SearchParams = Omit<v.InferOutput<typeof searchParamsSchema>, 'profiles'>

const buildSearchParams = (_searchParams: SearchParamsInput, params: SearchParamsInput) => {
  const searchParams = { ..._searchParams }

  let key: keyof typeof params
  for (key in params) {
    if (params[key] === undefined) {
      searchParams[key] = undefined
    } else {
      searchParams[key] = params[key] as any
    }
  }

  let key2: keyof typeof defaultSearchParams
  for (key2 in defaultSearchParams) {
    if (searchParams[key2] === defaultSearchParams[key2]) {
      searchParams[key2] = undefined
    }
  }

  return stringify(searchParams, { addQueryPrefix: true, encode: false })
}

export const getSearchParams = (search: string) => {
  const searchParams = parse(search, { ignoreQueryPrefix: true })
  // return searchParamsSchema.parse(searchParams)
  return v.parse(searchParamsSchema, searchParams)
}

export const getProfiles = (path: string, search: string): RouterInput['report']['getReport'] => {
  const searchParams = getSearchParams(search)
  const profiles = searchParams.profiles
    ? ({
        ...JSON.parse(searchParams.profiles),
        'type': searchParams.type,
        'org-type': searchParams['org-type'],
      } as RouterInput['report']['getReport'])
    : undefined

  if (profiles) return profiles

  return getProfilesFromPathname(path, search)
}

function getProfilesFromPathname(pathname: string, search: string): RouterInput['report']['getReport'] {
  const searchParams = getSearchParams(search)

  const meMatched = matchPath({ path: '/new/report', end: false }, pathname)
  if (meMatched != null) {
    return {
      'first': { type: 'applicant' },
      'selected': 'first',
      'type': searchParams.type,
      'org-type': searchParams['org-type'],
    }
  }

  const employeesReportPageMatched = matchPath({ path: '/new/employees/:employeeId', end: false }, pathname)
  if (employeesReportPageMatched?.params.employeeId != null) {
    return {
      'first': { type: 'employee', id: parseInt(employeesReportPageMatched.params.employeeId) },
      'selected': 'first',
      'type': searchParams.type,
      'org-type': searchParams['org-type'],
    }
  }

  const jobsReportPageMatched = matchPath({ path: '/new/jobs/:jobId', end: false }, pathname)
  if (jobsReportPageMatched?.params.jobId != null) {
    return {
      'first': { type: 'job', id: parseInt(jobsReportPageMatched.params.jobId) },
      'selected': 'first',
      'type': searchParams.type,
      'org-type': searchParams['org-type'],
    }
  }

  const teamsReportPageMatched = matchPath({ path: '/new/teams/:teamId', end: false }, pathname)
  if (teamsReportPageMatched?.params.teamId != null) {
    return {
      'first': { type: 'team', id: parseInt(teamsReportPageMatched.params.teamId) },
      'selected': 'first',
      'type': searchParams.type,
      'org-type': searchParams['org-type'],
    }
  }

  const marketReportPageMatched = matchPath({ path: '/new/market', end: false }, pathname)
  if (marketReportPageMatched) {
    return {
      'first': { type: 'market' },
      'selected': 'first',
      'type': searchParams.type,
      'org-type': searchParams['org-type'],
    }
  }

  const orgUserPageMatched = matchPath({ path: '/new/orgs/:orgId/groups/:groupId/report', end: false }, pathname)
  if (orgUserPageMatched) {
    return {
      'selected': 'first',
      'first': { type: 'org_group', id: orgUserPageMatched.params.groupId! },
      'type': searchParams.type,
      'org-type': searchParams['org-type'],
    }
  }

  const orgPageMatched = matchPath({ path: '/new/orgs/:orgId/groups/:groupId', end: false }, pathname)
  if (orgPageMatched) {
    return {
      'selected': 'first',
      'first': { type: 'org_group', id: orgPageMatched.params.groupId! },
      'type': searchParams.type,
      'org-type': searchParams['org-type'],
    }
  }

  return {
    'selected': 'first',
    'type': searchParams.type,
    'org-type': searchParams['org-type'],
  }
}

export const useSearchParams = () => {
  const location = useLocation()
  const searchParams = useMemo(() => getSearchParams(location.search), [location.search])

  const searchParamsWithProfiles = useMemo(() => {
    return {
      ...searchParams,
      profiles: getProfiles(location.pathname, location.search),
    }
  }, [searchParams, location.pathname, location.search])

  return {
    searchParams: searchParamsWithProfiles,
    buildSearchParams: (params: SearchParamsInput) => buildSearchParams(searchParams, params),
    pathname: getProfilesFromPathname(location.pathname, location.search),
  }
}

export const getTeamProfile = (teamId: string | undefined, profiles: RouterInput['report']['getReport']) => {
  if (teamId != null && profiles.first?.type === 'team' && profiles.first.id === Number(teamId)) {
    return profiles.first
  }
  if (teamId != null && profiles.second?.type === 'team' && profiles.second.id === Number(teamId)) {
    return profiles.second
  }
}

export function getTeamOptionsPrefetch(teamId: string, profiles: RouterInput['report']['getReport']) {
  const profile = getTeamProfile(teamId, profiles)

  return {
    teamId: Number(teamId),
    filters: {
      type: profiles.type,
      evaluation: profile?.evaluation,
      members: profile?.members,
    },
  }
}

export function getTeamOptions(teamId: string | undefined, profiles: RouterInput['report']['getReport']) {
  if (teamId == null) return skipToken

  return getTeamOptionsPrefetch(teamId, profiles)
}
