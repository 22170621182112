import type { IndicatorType, Locale } from 'types'
// import type { DistributionList } from '../../../../../apps/app/src/lib/distribution.ts'

type ColProps = {
  key: 'task' | 'people' | 'context' | 'titles.competences' | 'titles.vectors' | 'titles.dimensions'
  title: string | undefined
  list: {
    key: number
    type: IndicatorType
    title?: string
    tooltip?: string
    index?: number
    value?: number
    valueToCompare?: number
  }[]
  // | DistributionList[keyof DistributionList]
}

export const indicators: {
  orgs: ColProps[]
  basic: ColProps[]
  advanced: ColProps[]
} = {
  orgs: [
    {
      key: 'task',
      title: undefined,
      list: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((elem) => ({
        key: elem,
        type: 'competences',
      })),
    },
  ],
  basic: [
    { key: 'task', title: undefined, list: [0, 1, 2, 3, 4, 5].map((elem) => ({ key: elem, type: 'competences' })) },
    { key: 'people', title: undefined, list: [6, 7, 8, 9, 10, 11].map((elem) => ({ key: elem, type: 'competences' })) },
    {
      key: 'context',
      title: undefined,
      list: [12, 13, 14, 15, 16, 17].map((elem) => ({ key: elem, type: 'competences' })),
    },
  ],
  advanced: [
    {
      key: 'titles.competences',
      title: undefined,
      list: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((elem) => ({
        key: elem,
        type: 'competences',
      })),
    },
    {
      key: 'titles.vectors',
      title: undefined,
      list: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((elem) => ({
        key: elem,
        type: 'vectors',
      })),
    },
    {
      key: 'titles.dimensions',
      title: undefined,
      list: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((elem) => ({
        key: elem,
        type: 'dimensions',
      })),
    },
  ],
}

export const sortEmptySpaces = <T>(a: T, b: T) => (a == null ? 1 : b == null ? -1 : 0)

export const getIndicatorsColor = (type: string, id: number) => {
  const colourDivitions =
    type === 'comparison' || type === 'competences' || type === 'values'
      ? ([6, 12, 18] as const)
      : type === 'vectors' || type === 'principles'
        ? ([2, 4, 6] as const)
        : type === 'dimensions' || type === 'scopes'
          ? ([1, 2, 3] as const)
          : []

  return type === 'comparison'
    ? {
        normal: 'var(--color-radar-compare)',
        darker: 'var(--color-radar-compare)',
      }
    : type === 'positioning'
      ? {
          normal: 'var(--color-radar-hippie-blue)',
          darker: 'var(--color-radar-hippie-blue-darker)', // Hippie Blue
        }
      : type === 'task' || id < colourDivitions[0]
        ? {
            text: 'var(--color-radar-blue-text)',
            normal: 'var(--color-radar-blue)',
            darker: 'var(--color-radar-blue-darker)',
            // lighter: 'hsl(var(--accent))', // 'var(--color-radar-blue-lightest)',
            lighter: 'var(--color-radar-blue-lightest)',
            // disabled: 'var(--bg-color-2)', // 'var(--color-radar-blue-disabled)',
            disabled: 'var(--color-radar-blue-disabled)',
          }
        : type === 'people' || id < colourDivitions[1]
          ? {
              text: 'var(--color-radar-green-text)',
              normal: 'var(--color-radar-green)',
              darker: 'var(--color-radar-green-darker)',
              // lighter: 'hsl(var(--accent))', // 'var(--color-radar-green-lightest)',
              lighter: 'var(--color-radar-green-lightest)',
              // disabled: 'var(--bg-color-2)', // 'var(--color-radar-green-disabled)',
              disabled: 'var(--color-radar-green-disabled)',
            }
          : type === 'context' || id < colourDivitions[2]
            ? {
                text: 'var(--color-radar-yellow-text)',
                normal: 'var(--color-radar-yellow)',
                darker: 'var(--color-radar-yellow-darker)',
                // lighter: 'hsl(var(--accent))', // 'var(--color-radar-yellow-lightest)',
                lighter: 'var(--color-radar-yellow-lightest)',
                // disabled: 'var(--bg-color-2)', // 'var(--color-radar-yellow-disabled)',
                disabled: 'var(--color-radar-yellow-disabled)',
              }
            : {
                normal: 'var(--bg-color-2)',
                darker: 'var(--bg-color-2)',
              }
}

type AccentMapProps = Record<string, string>

export const unaccent = (string: string): string => {
  const accentMap: AccentMapProps = {
    à: 'a',
    á: 'a',
    â: 'a',
    ã: 'a',
    ä: 'a',
    å: 'a', // a
    ç: 'c', // c
    è: 'e',
    é: 'e',
    ê: 'e',
    ë: 'e', // e
    ì: 'i',
    í: 'i',
    î: 'i',
    ï: 'i', // i
    ñ: 'n', // n
    ò: 'o',
    ó: 'o',
    ô: 'o',
    õ: 'o',
    ö: 'o',
    ø: 'o', // o
    ß: 's', // s
    ù: 'u',
    ú: 'u',
    û: 'u',
    ü: 'u', // u
    ÿ: 'y', // y
  }

  if (!string) return ''

  let ret = ''
  for (let i = 0; i < string.length; ++i) ret += accentMap[string.charAt(i)] ?? string.charAt(i)
  return ret
}

export const filterList = <T extends Record<string, string | number>>(list: T[], value: string, key: string) => {
  const normalizedValue = value ? unaccent(value.toLowerCase()) : ''
  return list.filter((elem) => unaccent(elem[key]!.toString()).includes(normalizedValue))
}

export const getInitials = (name: string, type: string) => {
  if (type !== 'team') return name.substr(0, 1).toUpperCase()

  const nameParts = name.split(' ')
  return nameParts.length > 1
    ? nameParts
        .reduce((previous, current) => `${previous}${current.length > 2 ? current.charAt(0).toUpperCase() : ''}`, '')
        .substr(0, 2)
    : name.substr(0, 2).toUpperCase()
}

export const getAgeValue = (
  values: [number, number],
  opts = { showLimits: false },
): [string, 'year_one' | 'year_other'] =>
  (values[0] > 0 || values[1] < 90 || opts.showLimits) && values[0] !== values[1]
    ? [`${values[0]} - ${values[1]}`, values[1] === 1 ? 'year_one' : 'year_other']
    : values[0] > 0
      ? [values[0].toString(), values[0] === 1 ? 'year_one' : 'year_other']
      : [values[1].toString(), values[1] === 1 ? 'year_one' : 'year_other']

export const sortByDesc = (list: { name: string }[]) => list.sort((a, b) => new Intl.Collator().compare(a.name, b.name))

export const formatDate = (date: string, locale: Locale) =>
  new Intl.DateTimeFormat(locale, { year: 'numeric', month: 'short', day: 'numeric' }).format(new Date(date))

export const formatDatetime = (date: string, locale: Locale) =>
  new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date))

export const isEmpty = (input?: unknown) => input == null || input === ''

export const ignoreDiacritics = (text: string) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

export const normalizeTitle = (title: string) =>
  title.replace(' api=true', '').replace(' sdk=true', '').replace(' optional=true', '')

export const titleToAnchor = (title: string) =>
  typeof title !== 'string'
    ? title
    : ignoreDiacritics(normalizeTitle(title))
        .replace(/:|'|\(|\)|¿|\?|{|}/g, '')
        .replace(/ |\n|\.|\//g, '-')
        .replace(/---/g, '')
        .replace(/--/g, '-')
        // .replace(/[0-9]/g, '')
        .toLowerCase()

export const invalidForm = (
  requirements: Record<string, boolean | undefined>,
  inputs: Record<string, unknown>,
  loading: boolean,
) => loading || Object.entries(requirements).some(([name, isRequired]) => isRequired && isEmpty(inputs[name]))

export const basename =
  typeof window !== 'undefined' && window.location.pathname.startsWith('/danchiano') ? '/danchiano' : ''
