import { useStore } from '../../../../../apps/app/src/lib/store'
import { basename } from '.'

export class AppError extends Error {
  response
  constructor(message: { message: string } | string) {
    if (typeof message === 'object') {
      super(message.message)
      this.response = message
    } else {
      super(message)
    }
    this.name = this.constructor.name
  }
}

type Params = {
  clientId?: string
  userToken?: string
  method?: 'GET' | 'POST' | 'DELETE' | 'PATCH'
  contentType?: 'application/json' | null
  body?: BodyInit | null
}

function addRequestData({ clientId, userToken, method, contentType = 'application/json', body }: Params) {
  const request: {
    headers: Record<string, string>
    method?: Params['method']
    body?: Params['body']
  } = { headers: {} }

  if (clientId != null) {
    request.headers['Client-id'] = clientId
  } else if (window.clientId != null) {
    request.headers['Client-id'] = window.clientId
  }

  if (userToken) request.headers.Authorization = `Bearer ${userToken}`

  if (window.LOCALE) request.headers['Accept-language'] = window.LOCALE

  if (typeof window !== 'undefined') {
    if (window.document.referrer) {
      const pathArray = window.document.referrer.split('/')
      const origin = `${pathArray[0]}//${pathArray[2]}`
      request.headers['Iframe-origin'] = origin
    } else {
      const pathArray = window.location.href.split('/')
      const origin = `${pathArray[0]}//${pathArray[2]}`
      request.headers['Iframe-origin'] = origin
    }
  }

  if (method) {
    request.method = method
    if (contentType) request.headers['Content-Type'] = contentType
    if (body) {
      if (contentType === 'application/json') {
        request.body = JSON.stringify(body)
      } else {
        request.body = body
      }
    }
  }
  return request
}

export default function request(url: string, params?: Params) {
  // If basename is defined we're on /danchiano for buscojobs,
  // so we need to make cross-requests to the backend
  // TODO: change URL_FRONTEND to URL_BACKEND or URL_PLATFORM, think about it
  const baseUrl = basename !== '' ? window.URL_FRONTEND : ''
  return fetch(`${baseUrl}${url}`, addRequestData(params ?? {})).then(async (response) => {
    // If we have a new version mark as newVersionAvailable and reload on location change (on App.tsx)
    const lastVersion = response.headers.get('X-Client-Version')
    if (lastVersion && window.COMMIT_HASH && lastVersion !== window.COMMIT_HASH) {
      useStore.setState({ newVersionAvailable: true })
    }

    // // On development we add a delay of 500ms to simulate a slow connection
    // if (process.env.NODE_ENV === 'development') {
    //   await new Promise((resolve) => setTimeout(resolve, 500))
    // }

    // Manages json responses or other responses (like empty ones) as text
    if (response.headers.get('content-type')?.includes('application/json')) {
      return response.json().then((data) => {
        if (!response.ok) throw new AppError(data)
        return data
      })
    } else {
      return response.text().then((data) => {
        if (!response.ok) throw new AppError(data)
        return data
      })
    }
  })
}
