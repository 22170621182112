import type { AppRouter } from 'server/trpc'
import { createTRPCContext } from '@trpc/tanstack-react-query'
import { createTRPCReact } from '@trpc/react-query'
import { httpBatchLink, type HTTPHeaders } from '@trpc/client'
import { basename } from 'ui/src/common/utils'

export const trpcClient = createTRPCReact<AppRouter>().createClient({
  links: [
    httpBatchLink({
      url: `${basename}/trpc`,
      headers() {
        const headers: HTTPHeaders = {}

        const clientId = new URLSearchParams(location.search).get('clientId') ?? window.clientId
        const userToken = new URLSearchParams(location.search).get('token')

        if (clientId) headers['Client-id'] = clientId
        if (userToken) headers['Authorization'] = `Bearer ${userToken}`
        if (window.LOCALE) headers['Accept-language'] = window.LOCALE

        if (typeof window !== 'undefined') {
          if (window.document.referrer) {
            const pathArray = window.document.referrer.split('/')
            const origin = `${pathArray[0]}//${pathArray[2]}`
            headers['Iframe-origin'] = origin
          } else {
            const pathArray = window.location.href.split('/')
            const origin = `${pathArray[0]}//${pathArray[2]}`
            headers['Iframe-origin'] = origin
          }
        }

        return headers
      },
    }),
  ],
})

export const { TRPCProvider, useTRPC } = createTRPCContext<AppRouter>()
